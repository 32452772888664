/*// src/components/achievementCard/ComponentModal.js
import React from 'react';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ComponentModal({ open, handleClose, Component }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <Component />
      </Box>
    </Modal>
  );
}*/
// ComponentModal.js
/*import React from 'react';
import { Modal } from '@material-ui/core'; // or any other modal component library you use

export default function ComponentModal({ open, handleClose, Component }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal-content">
        {Component && <Component />}
      </div>
    </Modal>
  );
}*/
// ComponentModal.js
// ComponentModal.js
// ComponentModal.js
//import React from 'react';
/*import { Modal } from '@material-ui/core'; // Make sure @material-ui/core is installed
import './ComponentModal.scss'; // Make sure to import your SCSS file here

export default function ComponentModal({ open, handleClose, Component }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
          <div className="modal-inner-content">
            {Component && <Component />}
          </div>
        </div>
      </div>
    </Modal>
  );
}/*/
// ComponentModal.js
import React from 'react';
import { Modal } from '@material-ui/core'; // Ensure @material-ui/core is installed
import './ComponentModal.scss'; // Ensure to import your SCSS file here

export default function ComponentModal({ open, handleClose, Component }) {
  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
          <div className="modal-inner-content">
            {Component && <Component />}
          </div>
        </div>
      </div>
    </Modal>
  );
}