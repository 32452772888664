/*import React from 'react';

function App() {
  const handleRedirect = () => {
    window.open('https://www.example.com', '_blank');
  };

  return (
    <div>
      <h1>Welcome to My App</h1>
      <button onClick={handleRedirect}>
        Go to Example.com
      </button>
    </div>
  );
}

export default App;*/
/*import React from 'react';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import Main from './containers/Main';
import LegalNotice from './LegalNotice';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/legal-notice" element={<LegalNotice />} />
      </Routes>
    </Router>
  );
}

export default App;*/

import React from 'react';
import Main from './containers/Main';


function App() {
  return (
      <div>
        <Main/>
        </div>
        
      
  );
}
export default App;