import React, { useContext } from "react";
import Headroom from "react-headroom";
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import LegalNotice from "../../LegalNotice";
import Popup from 'reactjs-popup';

import {
  greeting,
  workExperiences,
  skillsSection,
  openSource,
  blogSection,
  talkSection,
  achievementSection,
  bigProjects
} from "../../portfolio";

function Header() {
  const { isDark } = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewOpenSource = openSource.display;
  const viewSkills = skillsSection.display;
  const viewAchievement = achievementSection.display;
  const viewBigProjects = bigProjects.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;
  const viewLegalNotice = bigProjects.display;

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/">
          <img src={require("./logo.png")} alt={"Test"} width="100px" height="50px" />
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{ color: "white" }}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewSkills && (
            <li>
              <a href="#skills">Über uns</a>
            </li>
          )}
          {viewExperience && (
            <li>
              <a href="#achievements">Forschungsfelder</a>
            </li>
          )}
          {viewAchievement && (
            <li>
              <a href="#experience">Services</a>
            </li>
          )}
          {viewBigProjects && (
            <li>
              <a href="#projects">Forschungsprojekte</a>
            </li>
          )}
          <li>
            <a href="#contact">Kontakt</a>
          </li>
          {viewLegalNotice && (
            <li>
              <Popup
                trigger={<a href="#" className="menu-item">Impressum</a>}
                modal
                closeOnDocumentClick
                contentStyle={{
                  width: "100%",
                  height: "100vh",
                  border: "none",
                  padding: 0
                }}
                overlayStyle={{
                  background: "rgba(0,0,0,0.5)"
                }}
              >
                {close => (
                  <div style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 20
                  }}>
                    <button onClick={close} style={{
                      position: "absolute",
                      top: 20,
                      right: 20,
                      border: "none",
                      background: "none",
                      fontSize: "24px",
                      cursor: "pointer"
                    }}>&times;</button>
                    <LegalNotice />
                  </div>
                )}
              </Popup>
            </li>
          )}
          <li>
            <a>
              <ToggleSwitch />
            </a>
          </li>
          <li>
            <a href="https://mat4nrg.com/" className="menu-item">English</a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}

export default Header;
