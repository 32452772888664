/*import React from "react";
import "./AchievementCard.scss";

export default function AchievementCard({cardInfo, isDark}) {
  function openUrlInNewTab(url, name) {
    if (!url) {
      console.log(`URL for ${name} not found`);
      return;
    }
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <div className={isDark ? "dark-mode certificate-card" : "certificate-card"}>
      <div className="certificate-image-div">
        <img
          src={cardInfo.image}
          alt={cardInfo.imageAlt || "Card Thumbnail"}
          className="card-image"
        ></img>
      </div>
      <div className="certificate-detail-div">
        <h5 className={isDark ? "dark-mode card-title" : "card-title"}>
          {cardInfo.title}
        </h5>
        <p className={isDark ? "dark-mode card-subtitle" : "card-subtitle"}>
          {cardInfo.description}
        </p>
      </div>
      <div className="certificate-card-footer">
        {cardInfo.footer1.map((v, i) => {
          return (
            <span
              key={i}
              className={
                isDark ? "dark-mode certificate-tag" : "certificate-tag"
              }
              onClick={() => openUrlInNewTab(v.url, v.name)}
            >
              {v.name}
            </span>
          );
        })}
        {cardInfo.footer2.map((v, i) => {
          return (
            <span
              key={i}
              className={
                isDark ? "dark-mode certificate-tag" : "certificate-tag"
              }
              onClick={() => openUrlInNewTab(v.url, v.name)}
            >
              {v.name}
            </span>
          );
        })}
        {cardInfo.footer3.map((v, i) => {
          return (
            <span
              key={i}
              className={
                isDark ? "dark-mode certificate-tag" : "certificate-tag"
              }
              onClick={() => openUrlInNewTab(v.url, v.name)}
            >
              {v.name}
            </span>
          );
        })}
          {cardInfo.footer4.map((v, i) => {
          return (
            <span
              key={i}
              className={
                isDark ? "dark-mode certificate-tag" : "certificate-tag"
              }
              onClick={() => openUrlInNewTab(v.url, v.name)}
            >
              {v.name}
            </span>
          );
        })}
          {cardInfo.footer5.map((v, i) => {
          return (
            <span
              key={i}
              className={
                isDark ? "dark-mode certificate-tag" : "certificate-tag"
              }
              onClick={() => openUrlInNewTab(v.url, v.name)}
            >
              {v.name}
            </span>
          );
        })}
      </div>
    </div>
  );
}

/*import React, { useState } from "react";
import "./AchievementCard.scss";
import ComponentModal from './ComponentModal';

export default function AchievementCard({ cardInfo, isDark }) {
  const [open, setOpen] = useState(false);
  const [Component, setComponent] = useState(null);

  function handleOpen(component) {
    setComponent(component);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setComponent(null);
  }

  return (
    <div className={isDark ? "dark-mode certificate-card" : "certificate-card"}>
      <div className="certificate-image-div">
        <img
          src={cardInfo.image}
          alt={cardInfo.imageAlt || "Card Thumbnail"}
          className="card-image"
        ></img>
      </div>
      <div className="certificate-detail-div">
        <h5 className={isDark ? "dark-mode card-title" : "card-title"}>
          {cardInfo.title}
        </h5>
        <p className={isDark ? "dark-mode card-subtitle" : "card-subtitle"}>
          {cardInfo.subtitle}
        </p>
      </div>
      <div className="certificate-card-footer">
        {cardInfo.footerLink1.map((v, i) => {
          return (
            <span
              key={i}
              className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
              onClick={() => handleOpen(v.component)}
            >
              {v.name}
            </span>
          );
        })}
        {cardInfo.footerLink2.map((v, i) => {
          return (
            <span
              key={i}
              className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
              onClick={() => handleOpen(v.component)}
            >
              {v.name}
            </span>
          );
        })}
        {cardInfo.footerLink3.map((v, i) => {
          return (
            <span
              key={i}
              className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
              onClick={() => handleOpen(v.component)}
            >
              {v.name}
            </span>
          );
        })}
        {cardInfo.footerLink4.map((v, i) => {
          return (
            <span
              key={i}
              className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
              onClick={() => handleOpen(v.component)}
            >
              {v.name}
            </span>
          );
        })}
        {cardInfo.footerLink5.map((v, i) => {
          return (
            <span
              key={i}
              className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
              onClick={() => handleOpen(v.component)}
            >
              {v.name}
            </span>
          );
        })}
      </div>
      <ComponentModal open={open} handleClose={handleClose} Component={Component} />
    </div>
  );
}*/
// AchievementCard.js
/*import React, { useState } from "react";
import "./AchievementCard.scss";
import ComponentModal from "./ComponentModal"; // Adjust the import path as necessary

export default function AchievementCard({ cardInfo, isDark }) {
  const [open, setOpen] = useState(false);
  const [Component, setComponent] = useState(null);

  const handleOpen = (Component) => {
    setComponent(() => Component);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setComponent(null);
  };

  return (
    <div className={isDark ? "dark-mode certificate-card" : "certificate-card"}>
      <div className="certificate-image-div">
        <img
          src={cardInfo.image}
          alt={cardInfo.imageAlt || "Card Thumbnail"}
          className="card-image"
        />
      </div>
      <div className="certificate-detail-div">
        <h5 className={isDark ? "dark-mode card-title" : "card-title"}>
          {cardInfo.title}
        </h5>
        <p className={isDark ? "dark-mode card-subtitle" : "card-subtitle"}>
          {cardInfo.subtitle}
        </p>
      </div>
      <div className="certificate-card-footer">
        {cardInfo.footerLink1.map((v, i) => (
          <span
            key={i}
            className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
            onClick={() => handleOpen(v.component)}
          >
            {v.name}
          </span>
        ))}
        {cardInfo.footerLink2.map((v, i) => (
          <span
            key={i}
            className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
            onClick={() => handleOpen(v.component)}
          >
            {v.name}
          </span>
        ))}
        {cardInfo.footerLink3.map((v, i) => (
          <span
            key={i}
            className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
            onClick={() => handleOpen(v.component)}
          >
            {v.name}
          </span>
        ))}
        {cardInfo.footerLink4.map((v, i) => (
          <span
            key={i}
            className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
            onClick={() => handleOpen(v.component)}
          >
            {v.name}
          </span>
        ))}
        {cardInfo.footerLink5.map((v, i) => (
          <span
            key={i}
            className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
            onClick={() => handleOpen(v.component)}
          >
            {v.name}
          </span>
        ))}
      </div>
      <ComponentModal open={open} handleClose={handleClose} Component={Component} />
    </div>
  );
}*/
// AchievementCard.js
import React from "react";
import "./AchievementCard.scss";

export default function AchievementCard({ cardInfo, isDark, handleOpen }) {
  return (
    <div className={isDark ? "dark-mode certificate-card" : "certificate-card"}>
      <div className="certificate-image-div">
        <img
          src={cardInfo.image}
          alt={cardInfo.imageAlt || "Card Thumbnail"}
          className="card-image"
        />
      </div>
      <div className="certificate-detail-div">
        <h5 className={isDark ? "dark-mode card-title" : "card-title"}>
          {cardInfo.title}
        </h5>
        <p className={isDark ? "dark-mode card-subtitle" : "card-subtitle"}>
          {cardInfo.subtitle}
        </p>
      </div>
      <div className="certificate-card-footer">
        {[...cardInfo.footer1, ...cardInfo.footer2, ...cardInfo.footer3, ...cardInfo.footer4, ...cardInfo.footer5].map((v, i) => (
          <span
            key={i}
            className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
            onClick={() => handleOpen(v.component)}
          >
            {v.name}
          </span>
        ))}
      </div>
    </div>
  );
}
